import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
  



  <path d="M2456 3818 c-3 -13 -6 -75 -6 -139 0 -134 4 -142 72 -137 l43 3 0
145 0 145 -51 3 c-46 3 -52 1 -58 -20z"/>
<path d="M2050 3729 c0 -21 69 -159 80 -159 24 0 20 33 -11 100 -23 50 -37 70
-50 70 -11 0 -19 -5 -19 -11z"/>
<path d="M2917 3664 c-34 -74 -38 -104 -12 -104 15 0 78 133 73 157 -7 36 -26
20 -61 -53z"/>
<path d="M1790 3553 c0 -19 95 -133 111 -133 36 0 4 64 -67 135 -21 20 -44 19
-44 -2z"/>
<path d="M3174 3520 c-58 -61 -72 -86 -56 -102 8 -8 24 3 61 43 54 58 69 85
53 101 -6 6 -27 -9 -58 -42z"/>
<path d="M2397 3354 c-4 -4 -7 -99 -7 -211 l0 -203 125 0 125 0 1 93 2 92 11
-85 c10 -78 11 -81 14 -36 2 26 -2 69 -9 95 -6 25 -15 93 -18 151 l-6 105
-116 3 c-63 1 -118 -1 -122 -4z m193 -89 l0 -55 -75 0 -75 0 0 55 0 55 75 0
75 0 0 -55z"/>
<path d="M2665 3325 c5 -11 12 -99 15 -195 l6 -175 2 181 c2 160 0 183 -15
195 -15 13 -16 12 -8 -6z"/>
<path d="M3311 3220 c-117 -57 -123 -61 -117 -85 6 -24 1 -28 -56 -54 -35 -16
-178 -81 -318 -146 -207 -95 -263 -117 -297 -116 -35 2 -45 -2 -60 -25 -11
-17 -48 -41 -100 -65 -85 -39 -94 -50 -69 -85 14 -18 17 -18 100 24 48 23 99
42 115 42 21 0 37 9 53 29 22 27 122 84 488 274 74 38 139 74 145 79 6 5 16 0
27 -14 l17 -23 113 55 c150 72 151 73 127 128 -10 23 -24 42 -31 42 -7 0 -69
-27 -137 -60z"/>
<path d="M1524 3205 c-7 -19 -14 -41 -14 -50 0 -16 189 -111 242 -122 25 -5
30 -1 43 30 24 58 20 66 -47 96 -35 15 -88 40 -119 54 -73 35 -87 34 -105 -8z"/>
<path d="M3290 2875 c0 -87 3 -105 15 -105 11 0 15 12 15 50 l0 50 47 0 c25 0
43 3 40 7 -4 4 -25 6 -47 6 -40 -1 -40 0 -40 38 l0 39 50 0 c28 0 50 5 50 10
0 6 -28 10 -65 10 l-65 0 0 -105z"/>
<path d="M1580 2815 l0 -105 72 0 c39 0 68 3 64 8 -4 4 -29 7 -54 7 l-47 0 -3
43 -3 42 50 0 c28 0 51 5 51 10 0 6 -22 10 -50 10 l-50 0 0 35 0 35 49 0 c28
0 53 5 56 10 4 6 -20 10 -64 10 l-71 0 0 -105z"/>
<path d="M1750 2509 c0 -2 -1 -103 -1 -224 l-1 -220 119 0 c110 0 121 2 163
27 56 33 85 63 105 110 40 96 0 218 -90 273 -46 28 -55 30 -172 34 -68 1 -123
1 -123 0z m220 -92 c63 -32 89 -129 54 -202 -22 -47 -58 -67 -118 -69 l-51 -1
-3 143 -3 142 48 0 c27 0 59 -6 73 -13z"/>
<path d="M2660 2465 c0 -38 -3 -45 -20 -45 -17 0 -20 -7 -20 -45 0 -38 3 -45
20 -45 19 0 20 -7 20 -102 1 -70 5 -109 14 -120 19 -25 79 -48 126 -48 l40 0
0 45 c0 43 -1 45 -28 45 -44 0 -52 16 -52 102 l0 78 40 0 40 0 0 45 0 45 -40
0 -40 0 0 45 0 45 -50 0 -50 0 0 -45z"/>
<path d="M2275 2405 c-62 -31 -103 -129 -86 -205 27 -119 151 -181 230 -114
31 26 35 25 32 -8 -2 -15 6 -18 53 -18 l56 0 0 181 0 180 -52 -3 c-33 -2 -53
-8 -54 -15 -1 -7 -2 -16 -3 -19 0 -3 -13 4 -27 15 -32 25 -104 28 -149 6z
m149 -96 c36 -29 37 -99 1 -134 -59 -59 -146 -2 -129 84 14 67 76 91 128 50z"/>
<path d="M2971 2407 c-45 -23 -83 -82 -89 -136 -7 -75 4 -114 46 -161 33 -37
44 -43 89 -47 41 -4 60 -1 88 16 44 26 45 26 45 1 0 -17 7 -20 50 -20 l50 0 0
180 0 180 -50 0 c-43 0 -50 -3 -50 -20 0 -11 -3 -20 -6 -20 -3 0 -17 9 -31 20
-30 23 -104 27 -142 7z m144 -94 c57 -57 27 -163 -47 -163 -72 0 -109 114 -52
159 32 25 76 27 99 4z"/>
<path d="M2270 1700 l0 -240 55 0 55 0 0 240 0 240 -55 0 -55 0 0 -240z"/>
<path d="M3570 1700 l0 -240 55 0 c48 0 55 2 55 20 0 20 0 20 33 0 20 -13 50
-20 80 -20 44 0 52 4 96 48 39 39 49 56 53 92 12 86 -16 157 -76 198 -44 30
-123 30 -160 1 l-26 -20 0 80 0 81 -55 0 -55 0 0 -240z m237 6 c22 -19 28 -32
28 -66 0 -34 -6 -47 -28 -66 -34 -29 -66 -30 -101 -3 -22 17 -26 28 -26 69 0
41 4 52 26 69 35 27 67 26 101 -3z"/>
<path d="M1080 1685 l0 -225 55 0 55 0 0 95 0 95 71 0 70 0 -3 38 -3 37 -67 3
-68 3 0 49 0 50 90 0 90 0 0 40 0 40 -145 0 -145 0 0 -225z"/>
<path d="M2650 1686 l0 -226 49 0 c59 0 61 4 61 111 l0 79 85 0 85 0 0 -95 0
-95 55 0 55 0 0 225 0 225 -55 0 -55 0 0 -90 0 -90 -85 0 -84 0 -3 88 -3 87
-52 3 -53 3 0 -225z"/>
<path d="M1440 1690 c0 -144 8 -173 59 -209 38 -27 105 -28 149 -1 41 25 46
25 39 0 -5 -18 0 -20 49 -20 l54 0 0 180 0 180 -52 0 -53 0 0 -116 c0 -110 -1
-117 -24 -135 -30 -24 -62 -24 -92 0 -22 18 -24 27 -27 135 l-4 116 -49 0 -49
0 0 -130z"/>
<path d="M1943 1805 c-17 -7 -44 -33 -60 -56 -24 -36 -28 -53 -28 -109 0 -54
5 -73 25 -103 51 -75 149 -98 236 -56 39 18 88 74 79 89 -3 6 -76 -2 -157 -16
-29 -5 -40 -2 -57 15 -12 12 -19 26 -16 31 4 6 60 10 127 10 l120 0 -4 54 c-5
65 -42 121 -94 142 -42 18 -129 17 -171 -1z m135 -91 c42 -29 28 -44 -42 -44
-73 0 -86 10 -56 40 24 24 67 26 98 4z"/>
<path d="M3122 1693 c6 -169 31 -219 117 -230 50 -7 86 2 111 27 20 20 20 20
20 -5 0 -24 3 -25 55 -25 l55 0 0 180 0 180 -55 0 -55 0 0 -115 c0 -102 -2
-117 -20 -135 -32 -32 -91 -23 -109 16 -6 14 -11 72 -11 129 l0 105 -56 0 -56
0 4 -127z"/>
</g>
</svg>



        
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}